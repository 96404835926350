<template>
  <div class="p-6 md:p-8">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Company Information</span
    >
  </div>
  <hr class="text-dark-100" />
  <div class="p-6 md:p-8 flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Job Title</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.job && employee.job.jobTitle)
        }}</span>
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Employee ID</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.job && employee.job.userName)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Employment Type</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.job && employee.job.employmentType)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Branch</span>
        <span class="text-base text-dark-800 font-medium capitalize">{{
          display(employee && employee.branch && employee.branch.name)
        }}</span>
      </div>

      <!-- <div class="flex flex-col">
        <span class="text-sm text-dark-500">Branch Heading</span>
        <span class="text-base text-dark-800 font-medium capitalize">{{
          getHeads(employee && employee._id, "branch")
        }}</span>
      </div> -->

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Supervisor/Line Manager</span>
        <span class="text-base text-dark-800 font-medium">
          {{
            employee && employee.job && employee.job.reportTo
              ? getEmpName(employee && employee.job && employee.job.reportTo)
              : "N/A"
          }}
        </span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Department</span>
        <span class="text-base text-dark-800 font-medium capitalize">{{
          display(employee && employee.department && employee.department.name)
        }}</span>
      </div>

      <!-- <div class="flex flex-col">
        <span class="text-sm text-dark-500">Department Heading</span>
        <span class="text-base text-dark-800 font-medium capitalize">{{
          getHeads(employee && employee._id, "department")
        }}</span>
      </div> -->

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Start Date</span>
        <span class="text-base text-dark-800 font-medium">{{
          formatDateString(
            display(employee && employee.job && employee.job.hireDateString)
          )
        }}</span>
      </div>
    </div>
    <easiRoundLoader v-if="loading" />
  </div>
</template>

<script setup>
import { computed, ref } from "@vue/reactivity";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDateString } = helperFunctions;

const store = useDataStore();
const route = useRoute();
const { query } = store;
const { getAllDepartments, getAllBranches } = storeToRefs(store);
const fromArchived = computed(() => store.getViewFromArchived);
const loading = ref(false);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

function getEmpName(id) {
  if (id) {
    const emp = getEmployeeByID(id);
    return emp ? `${emp.profile.firstName} ${emp.profile.lastName}` : "N/A";
  }
  return "N/A";
}

function getEmployeeByID(id) {
  let emp1 = computed(() => store.getEmployeeById(id));
  let emp2 = computed(() => store.getArchivedEmployeeById(id));
  if (emp1.value) {
    return emp1.value;
  } else if (emp2.value) {
    return emp2.value;
  } else {
    return null;
  }
}

const queryDepartment = async () => {
  try {
    await query({
      endpoint: "ListDepartments",
      service: "SETTINGS",
      storeKey: "listDepartments",
    });
  } catch (err) {
    console.log(err);
  }
};
const queryBranch = async () => {
  loading.value = true;
  try {
    await query({
      endpoint: "ListBranches",
      service: "SETTINGS",
      storeKey: "listBranches",
    });
    loading.value = false;
    console.log("Branches");
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};

queryDepartment();
queryBranch();

function getHeads(id, type) {
  let head = [];
  if (
    type === "branch" &&
    getAllBranches.value &&
    getAllBranches.value.length
  ) {
    head = getAllBranches.value.filter((b) => b.branchHead._id === id);
  } else if (
    type === "department" &&
    getAllDepartments.value &&
    getAllDepartments.value.length
  ) {
    head = getAllDepartments.value.filter((d) => d.departmentHead._id === id);
  }
  return head.length ? head[0].name : "N/A";
}

// onMounted(()=> {
//   queryDepartment();
//   queryBranch()
// })
const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
