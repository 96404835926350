<template>
  <main
    class="flex bg-white md:bg-transparent rounded-md pt-4 border border-dark-100 md:p-0 md:border-0 flex-col md:flex-row items-start"
  >
    <div
      class="flex mx-4 md:mx-0 flex-row flex-wrap md:flex-col w-full pr-4 md:pr-0 md:w-52 md:items-start"
    >
      <button
        v-for="(btn, i) in pages"
        :key="i"
        @click="switchView(i)"
        :class="
          btn.active
            ? 'bg-white  border-outlineGray md:rounded-l-xl md:shadow-lg text-secondary font-semibold'
            : 'text-dark-800 border-dark-100 md:border-0'
        "
        class="capitalize text-sm px-4 py-3 focus:outline-none text-center md:text-left whitespace-nowrap w-fit border border-dark-100 md:border-0 md:w-full"
      >
        {{ btn.title }}
      </button>
    </div>
    <div
      class="relative w-full text-sm h-auto lg:w-8/12 bg-white md:rounded-tr-none md:rounded-tr-xl md:rounded-tl-none md:rounded-xl md:border md:border-dark-50 md:shadow-md"
    >
      <span
        v-show="edit"
        @click="toggleView"
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/close.svg" alt="" />
      </span>
      <span
        v-show="!edit"
        @click="toggleView"
        class="absolute top-4 right-4 cursor-pointer"
      >
        <img src="@/assets/icons/edit.svg" alt="" />
        <!-- <img class="" src="@/assets/icons/close.svg" alt="" /> -->
      </span>
      <span></span>
      <keep-alive>
        <component :is="display" />
      </keep-alive>
    </div>
  </main>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, shallowRef } from "vue";
import { useRoute } from "vue-router";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

//// View Components ////
import ProfileView from "@/components/Employee/View/Personal/Profile.vue";
import FamilyView from "@/components/Employee/View/Personal/Family.vue";
import HealthView from "@/components/Employee/View/Personal/Health.vue";
import CompanyView from "@/components/Employee/View/Personal/CompanyInfo.vue";

//// Edit Components ///
import ProfileEdit from "@/components/Employee/Edit/Personal/Profile.vue";
import FamilyEdit from "@/components/Employee/Edit/Personal/Family.vue";
import HealthEdit from "@/components/Employee/Edit/Personal/Health.vue";
import CompanyEdit from "@/components/Employee/Edit/Personal/CompanyInfo.vue";

const route = useRoute();
const store = useDataStore();
const { pageIndex } = storeToRefs(store);

const display = shallowRef(ProfileEdit);
const currentPage = ref({});
const edit = ref(false);

const pages = reactive([
  {
    title: "Personal Profile",
    component: ProfileEdit,
    viewComponent: ProfileView,
    active: true,
    live: "view",
  },
  {
    title: "Family Information",
    component: FamilyEdit,
    viewComponent: FamilyView,
    active: false,
    live: "view",
  },
  {
    title: "Health",
    component: HealthEdit,
    viewComponent: HealthView,
    active: false,
    live: "view",
  },
  {
    title: "Company Information",
    component: CompanyEdit,
    viewComponent: CompanyView,
    active: false,
    live: "view",
  },
]);

onMounted(() => {
  console.log(pageIndex.value);
  switchView(pageIndex.value);
});

onUnmounted(() => {
  store.$patch({
    pageIndex: 0,
  });
});
const toggleView = () => {
  if (edit.value === false) {
    display.value = currentPage.value.component;
    edit.value = true;
  } else {
    display.value = currentPage.value.viewComponent;
    edit.value = false;
  }
};

const switchView = (index) => {
  currentPage.value = pages[index];
  display.value = pages[index].viewComponent;
  pages.forEach((el) => {
    el.active = false;
  });
  pages[index].active = true;
};

// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>

<!-- <template>
  <main>
    <ProfileComponent />
    <FamilyComponent />
    <HealthComponent />
    <CompanyInfo />
    <EducationHistory />
    <Certification />
    <Award />
    <JobHistory />
    <Document />
    <Asset />
    <Emergency />
    <Benefits />
    <Deduction />
  </main>
</template>

<script setup>
import ProfileComponent from "@/components/Employee/Edit/Personal/Profile.vue";
import FamilyComponent from "@/components/Employee/Edit/Personal/Family.vue";
import HealthComponent from "@/components/Employee/Edit/Personal/Health.vue";
import CompanyInfo from "@/components/Employee/Edit/Personal/CompanyInfo.vue";

//Education Components
import EducationHistory from "@/components/Employee/Edit/Education/EducationHistory.vue";
import Certification from "@/components/Employee/Edit/Education/Certification.vue";
import Award from "@/components/Employee/Edit/Education/Award.vue";

//Job Component
import JobHistory from "@/components/Employee/Edit/Job/JobHistory.vue";

//Document Component
import Document from "@/components/Employee/Edit/Documents/Documents.vue";

//Asset
import Asset from "@/components/Employee/Edit/Asset/Asset.vue";

//Emergency
import Emergency from "@/components/Employee/Edit/Emergency/Emergency.vue";

//Benefit
import Benefits from "@/components/Employee/Edit/Benefits/Benefits.vue";
import Deduction from "@/components/Employee/Edit/Benefits/Deduction.vue";
</script>

<style></style> -->
