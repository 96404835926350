<template>
  <div class="p-6 md:p-8">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Family Information</span
    >
  </div>
  <hr class="text-dark-100" />
  <div class="p-6 md:p-8 flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Marital Status</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(
            employee &&
              employee.family &&
              employee.family &&
              employee.family.maritalStatus
          )
        }}</span>
      </div>

      <div class="uppercase text-secondary text-xl font-medium">
        Next of kin
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Name</span>
        <span class="text-base text-dark-800 font-medium">
          {{
            display(
              employee &&
                employee.family &&
                employee.family.nextOfKin &&
                employee.family.nextOfKin.name
            )
          }}
        </span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Email Address</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(
            employee &&
              employee.family &&
              employee.family.nextOfKin &&
              employee.family.nextOfKin.email
          )
        }}</span>
      </div>
      <div
        v-if="
          employee && employee.family && employee.family.nextOfKin.phoneNumbers
        "
        class="flex flex-col"
      >
        <span class="text-sm text-dark-500">Phone Number(s)</span>
        <div
          class="text-base text-dark-800 font-medium flex items-start justify-start flex-wrap gap-2"
        >
          <span
            v-for="(phone, i) in employee.family.nextOfKin.phoneNumbers"
            :key="i"
            >{{ display(phone) }}</span
          >
        </div>
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Home Address</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(
            employee &&
              employee.family &&
              employee.family.nextOfKin &&
              employee.family.nextOfKin &&
              employee.family.nextOfKin.address
          )
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Relationship</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(
            employee &&
              employee.family &&
              employee.family.nextOfKin &&
              employee.family.nextOfKin &&
              employee.family.nextOfKin.relationship
          )
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDateString } = helperFunctions;

const store = useDataStore();
const route = useRoute();
const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);
const nextOfKin = computed(
  () => employee && employee.family && employee.family.nextOfKin
);

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
