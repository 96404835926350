<template>
  <div class="md:full w-full py-10 mx-auto text-left">
    <div class="mb-6">
      <h2
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Edit Personal Profile
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="updateEmployeeProfile"
      autocomplete="on"
      class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <div class="flex gap-4 mb-8 w-full">
        <!-- <div
          :style="{
            backgroundImage: ' url(' + url + ')',
          }"
          class="w-14 h-16 border-2 bg-cover bg-no-repeat border-lightBorder rounded-md"
        ></div> -->
        <UploadComponent
          :imageLink="args.pfp"
          :profile="true"
          @fileUrl="uploadFile = $event"
        />
      </div>

      <label class="text-dark-600 text-sm font-medium">Select Title</label>
      <easiSelectInput2
        required
        class="mt-1 mb-0"
        @update="args.title = $event"
        :value="args.title"
        :options="[
          { label: 'Mr', value: 'Mr' },
          { label: 'Mrs', value: 'Mrs' },
          { label: 'Miss', value: 'Miss' },
        ]"
      />

      <!-- <label for="firstName" class="text-dark-600 text-sm font-medium"
        >First Name</label
      > -->
      <easiTextInput
        placeholder="First Name"
        type="text"
        name="firstName"
        class="mt-1 mb-0"
        v-model="args.firstName"
        required
      ></easiTextInput>

      <!-- <label for="lastName" class="text-dark-600 text-sm font-medium"
        >Last Name</label
      > -->
      <easiTextInput
        placeholder="Last Name"
        type="text"
        name="lastName"
        class="mt-1 mb-0"
        v-model="args.lastName"
        required
      ></easiTextInput>

      <!-- <label for="email" class="text-dark-600 text-sm font-medium"
        >Personal Email</label
      > -->
      <easiTextInput
        placeholder="Personal email"
        type="email"
        name="email"
        class="mt-1 mb-5"
        v-model="args.personalEmail"
        :error="errorRules.email"
        autocom
      ></easiTextInput>

      <!-- <label for="workemail" class="text-dark-600 text-sm font-medium"
        >Work Email</label
      > -->
      <easiTextInput
        placeholder="Work email"
        type="email"
        name="workemail"
        class="mt-1 mb-0"
        v-model="args.email"
        :error="errorRules.workEmail"
      ></easiTextInput>

      <label class="text-dark-600 text-sm font-medium mt-4"
        >Select Gender</label
      >
      <easiSelectInput2
        required
        class="mt-1 mb-2"
        @update="args.gender = $event"
        :value="args.gender"
        :options="[
          { label: 'Male', value: 'MALE' },
          { label: 'Female', value: 'FEMALE' },
          { label: 'Other', value: 'OTHER' },
        ]"
      />

      <easiTextArea
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-2"
        name="address"
        id="address"
        placeholder="Home Address"
        cols="30"
        v-model="args.address"
        rows="5"
      ></easiTextArea>

      <!-- <label for="phoneNumber" class="text-dark-600 text-sm font-medium"
        >Phone Number</label
      > -->
      <easiTextInput
        placeholder="Phone Number"
        type="number"
        name="phoneNumber"
        class="mt-1 mb-0"
        v-model="args.phone"
        :error="errorRules.phone"
      ></easiTextInput>

      <!-- <label for="otherPhoneNumbers" class="text-dark-600 text-sm font-medium"
        >Other Phone Numbers</label
      > -->
      <easiTextInput
        placeholder="Other Numbers"
        type="text"
        name="otherPhoneNumbers"
        class="mt-1 mb-5"
        :error="errorRules.otherPhoneNumbers"
        v-model="otherNumbers"
      ></easiTextInput>

      <label for="dob" class="mt-4 text-dark-600 text-sm font-medium"
        >Date of Birth</label
      >
      <!-- <easiDate></easiDate> -->
      <input
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
        type="date"
        name="dob"
        :max="today"
        v-model="args.dob"
        id="dob"
      />

      <label class="text-dark-600 text-sm font-medium">State of Origin</label>
      <easiSelectInput2
        required
        class="mt-1 mb-2"
        @update="args.state = $event"
        :value="args.state"
        :options="statesData"
      />

      <label class="text-dark-600 text-sm font-medium">Religion</label>
      <easiSelectInput2
        required
        class="mt-1 mb-5"
        @update="args.religion = $event"
        :value="args.religion"
        :options="[
          { label: 'Christian', value: 'CHRISTIAN' },
          { label: 'Islam', value: 'ISLAM' },
          { label: 'Other', value: 'OTHER' },
        ]"
      />

      <label
        v-if="args.religion === 'Others'"
        for="otherReligion"
        class="text-dark-600 text-sm font-medium"
        >Input Religion</label
      >
      <easiTextInput
        v-if="args.religion === 'Others'"
        placeholder="Input Religion"
        type="text"
        v-model="args.religion"
        name="otherReligion"
        class="mt-1 mb-5"
      ></easiTextInput>

      <div
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="reloadPage"
          class="md:w-40 w-full cursor-pointer text-center bg-white rounded-full text-dark-600 border border-primary text-primary font-bold px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white w-80 md:w-40"
        >
          Update
        </easiButton>
      </div>
    </form>

    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee's profile updated successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import UploadComponent from "@/components/global/UploadComponent";

import { ref, reactive, nextTick, watch, computed } from "vue";
import { useRoute } from "vue-router";

import NigerianStates from "@/utils/states";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";
import { useDataStore } from "@/stores/data.js";
import { storeToRefs } from "pinia";

const route = useRoute();
const store = useDataStore();
const { query, mutate } = store;
const { listEmployees, getAllEmployees } = storeToRefs(store);
const toast = useToast();

const {
  processNumber,
  processDate,
  validatePhone,
  validateEmail,
  uploadFileToServer,
} = helperFunctions;
const otherNumbers = ref("");
let updateSuccess = ref(false);
const today = ref("");
today.value = processDate();
const uploadFile = ref(null);

const statesData = computed(() => {
  let stateArray = [];
  stateArray = NigerianStates.map((state) => {
    return {
      label: state,
      value: state,
    };
  });
  console.log(stateArray);
  return stateArray;
});

const payload = reactive({
  paging: {
    limit: 1000,
    skip: 0,
    search: "",
    sort: { firstName: "ASC" },
  },
  filter: [],
});

const errorRules = ref({
  phone: "",
  email: "",
  workEmail: "",
  otherPhoneNumbers: "",
});
const loading = ref(false);

const args = reactive({
  firstName: "",
  lastName: "",
  middleName: "",
  email: "",
  personalEmail: "",
  phone: "",
  otherPhoneNumbers: [],
  address: "",
  country: "",
  dob: "",
  state: "",
  gender: "",
  religion: "",
  title: "",
  pfp: "",

  relationshipStatus: "",
  nin: "String",
  taxId: "",
});
const hide = ref(true);
let url = ref("");
// let url = ref("");
function previewFiles(e) {
  const file = e.target.files[0];
  let reader = new FileReader();

  reader.onloadend = (e) => {
    // data url
    url.value = e.target.result;
    console.log(e.target.result, "K");
  };
  reader.readAsDataURL(file);
  // url = URL.createObjectURL(file);
}
// const allEmployees = computed(() => getAllEmployees);
const employeeId = route.params.id;
function reloadPage() {
  store.$patch({
    pageIndex: 0,
  });
  window.location.reload();
}
function queryEmployees() {
  query({
    endpoint: "ListEmployees",
    payload: payload,
    service: "EMP",
    storeKey: "listEmployees",
  });
}
function getEmployeeById(id) {
  let employee = [];
  if (employeeId) {
    employee = getAllEmployees.value.data.filter((emp) => emp._id === id);
  }
  return employee;
}
function assignValues() {
  const keys = Object.keys(employees[0].profile);
  const argkeys = Object.keys(args);
  // newObj = keys.map((k) => {
  //   argkeys.map((ak) => {
  //     if (k === ak) {
  //       args[ak] = employees[k];
  //     }
  //   });
  // });
  for (let k of keys) {
    for (let ak of argkeys) {
      if (k === ak) {
        args[ak] = employees[0].profile[k];
      }
    }
  }
  if (args.otherPhoneNumbers) {
    otherNumbers.value = args.otherPhoneNumbers.join(",");
  }
  console.log(args);
  if (args.pfp) {
    url.value = args.pfp;
  }
}

async function updateEmployeeProfile() {
  if (uploadFile.value) {
    args.pfp = await uploadFileToServer(uploadFile.value);
  }

  if (otherNumbers.value) {
    if (otherNumbers.value.includes(",")) {
      args.otherPhoneNumbers = otherNumbers.value.split(",");
    } else {
      let phone = [];
      phone.push(otherNumbers.value);
      args.otherPhoneNumbers = phone;
    }
  }

  if (args && args.otherPhoneNumbers && args.otherPhoneNumbers.length) {
    for (let num of args.otherPhoneNumbers) {
      if (!validatePhone(num)) {
        errorRules.value.otherPhoneNumbers =
          "Please ensure all phone numbers are valid";
        window.scrollTo({ top: 0, behavior: "smooth" });
        throw new Error("Invalid");
      }
    }
  }
  if (args.phone && args.phone.length) {
    if (!validatePhone(args.phone)) {
      errorRules.value.phone = "Please ensure all phone numbers are valid";
      window.scrollTo({ top: 0, behavior: "smooth" });
      throw new Error("Invalid");
    }
  }

  if (args.personalEmail && args.personalEmail.length) {
    if (!validateEmail(args.personalEmail)) {
      errorRules.value.email = "Please enter a valid email address";
      window.scrollTo({ top: 0, behavior: "smooth" });
      throw new Error("Invalid");
    }
  }

  if (args.email && args.email.length) {
    if (!validateEmail(args.email)) {
      errorRules.value.workEmail = "Please enter a valid email address";
      window.scrollTo({ top: 0, behavior: "smooth" });
      throw new Error("Invalid");
    }
  }
  loading.value = true;
  // console.log(args);
  // delete args["religion"];
  mutate({
    endpoint: "UpdateEmployeeProfile",
    data: {
      updateEmployeeProfileId: route.params.id,
      profile: args,
    },
    service: "EMP",
  })
    .then((res) => {
      loading.value = false;
      console.log(res);
      if (res.success) {
        store.$patch({
          pageIndex: 0,
        });
        queryEmployees();
        // toast.success("Employee Profile Updated Successfuly");
        updateSuccess.value = true;
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        toast.error(res.message);
      }
    })
    .catch((e) => {
      console.log(e);
      toast.error(e.message);
      loading.value = false;
    });
}

queryEmployees();
const employees = getEmployeeById(route.params.id);
assignValues();
</script>

<style></style>
