<template>
  <div class="p-6 md:p-8">
    <span class="md:text-3xl text-xl text-dark-800 font-bold md:font-medium"
      >Health</span
    >
  </div>
  <hr class="text-dark-100" />
  <div class="p-6 md:p-8 flex flex-col gap-6">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Blood Group</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.health && employee.health.bloodGroup)
        }}</span>
      </div>

      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Genotype</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.health && employee.health.genotype)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Disabilities</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.health && employee.health.disabilities)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Health History</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.health && employee.health.healthHistory)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const store = useDataStore();
const route = useRoute();
const fromArchived = computed(() => store.getViewFromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);

const display = (arg) => {
  return arg ? arg : "N/A";
};
</script>

<style></style>
