<template>
  <div class="md:full w-full py-10 mx-auto text-left">
    <div class="mb-6">
      <h2
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Edit Company Information
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="UpdateEmployeeJob"
      autocomplete="on"
      class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <!-- <label for="jobTitle" class="text-dark-600 text-sm font-medium"
        >Job Title</label
      > -->
      <easiTextInput
        placeholder="Job Title"
        type="text"
        name="jobTitle"
        class="mt-1 mb-5"
        v-model="args.jobTitle"
        required
      ></easiTextInput>

      <!-- <label for="employeeId" class="text-dark-600 text-sm font-medium"
        >Employee ID</label
      > -->
      <easiTextInput
        placeholder="ID"
        type="text"
        name="employeeId"
        v-model="args.userName"
        required
        class="mt-1 mb-5"
      ></easiTextInput>

      <label for="employmentType" class="mt-4 text-dark-600 text-sm font-medium"
        >Employment Type</label
      >

      <easiSelectInput2
        required
        class="mt-1 mb-2"
        @update="args.employmentType = $event"
        :value="args.employmentType"
        :options="[
          {
            label: 'Fulltime',
            value: 'FULLTIME',
          },
          {
            label: 'Contract',
            value: 'CONTRACT',
          },
          {
            label: 'Part Time',
            value: 'PARTTIME',
          },
          {
            label: 'Volunteer',
            value: 'VOLUNTEER',
          },

          {
            label: 'NYSC',
            value: 'NYSC',
          },
          {
            label: 'Internship',
            value: 'INTERNSHIP',
          },

          {
            label: 'Apprentice',
            value: 'APPRENTICE',
          },
        ]"
      />

      <label for="branch" class="text-dark-600 text-sm font-medium"
        >Branch</label
      >

      <easiSelectInput2
        required
        class="mt-1 mb-2"
        @update="args.branchId = $event"
        :value="args.branchId"
        :options="formatBranches"
        autoHeight
      />

      <label class="text-dark-600 text-sm font-medium"
        >Supervisor/ Line Manager
      </label>
      <easiEmployeeSelect
        required
        class="mt-1 mb-2"
        @update="args.reportTo = $event"
        :value="args.reportTo"
        :options="mapEmpToId()"
      />

      <label for="dept" class="text-dark-600 text-sm font-medium"
        >Department</label
      >

      <easiSelectInput2
        required
        class="mt-1 mb-2"
        @update="args.departmentId = $event"
        :value="args.departmentId"
        :options="formatDepartments"
        autoHeight
      />

      <label for="startDate" class="text-dark-600 text-sm font-medium"
        >Start Date</label
      >
      <input
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
        type="date"
        name="startDate"
        id="startDate"
        :max="today"
        v-model="args.hireDateString"
      />

      <div
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="reloadPage"
          class="md:w-40 w-full cursor-pointer text-center bg-white rounded-full text-dark-600 border border-primary text-primary font-bold px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white w-80 md:w-40"
        >
          Update
        </easiButton>
      </div>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee's job info updated successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import { helperFunctions } from "@/composable/helperFunctions";

const store = useDataStore();
const route = useRoute();
const toast = useToast();
const { processDate, log } = helperFunctions;

const { mutate } = store;
const updateSuccess = ref(false);
const today = ref("");
today.value = processDate();
const employee = computed(() => store.getEmployeeById(route.params.id));

const allEmployees = computed(() =>
  store.getAllEmployees ? store.getAllEmployees : {}
);

const allBranches = computed(() =>
  store.getAllBranches ? store.getAllBranches.data : []
);
const allDepartments = computed(() =>
  store.getAllDepartments ? store.getAllDepartments.data : []
);

const formatBranches = computed(() => {
  return allBranches.value.map((el) => {
    return {
      label: el.name,
      value: el.name,
    };
  });
});

const formatDepartments = computed(() => {
  return allDepartments.value.map((el) => {
    return {
      label: el.name,
      value: el.name,
    };
  });
});

const getBranchByName = (name) => {
  return allBranches.value.find((el) => el.name === name);
};
const getDepartmentByName = (name) => {
  return allDepartments.value.find((el) => el.name === name);
};
const getBranchByID = (id) => {
  return allBranches.value.find((el) => el._id === id);
};
const getDepartmentByID = (id) => {
  return allDepartments.value.find((el) => el._id === id);
};

function mapEmpToId() {
  const mapped = allEmployees.value.data.map((emp) => {
    const obj = {
      label: `${emp.profile.firstName} ${emp.profile.lastName}`,
      value: emp._id,
    };
    return obj;
  });
  return mapped;
}

let args = reactive({
  jobTitle: "",
  branchId: "",
  departmentId: "",
  teamId: "",
  userName: "",
  employmentType: "",
  reportTo: "",
  hireDateString: "",
  staffLevelId: "",
});

const loading = ref(false);
const emp = employee.value.job;

const keys = Object.keys(args);

const arr = keys.map((k) => {
  if (emp[k]) {
    args[k] = emp[k];
    return true;
  }
  return false;
});

onMounted(() => {
  if (args.branchId && args.branchId.length > 0) {
    args.branchId = getBranchByID(args.branchId).name;
  }
  if (args.departmentId && args.departmentId.length > 0) {
    args.departmentId = getDepartmentByID(args.departmentId).name;
  }

  args.hireDateString = processDate(args.hireDateString);
});

delete args.__typename;
console.log(args);
function reloadPage() {
  store.$patch({
    pageIndex: 3,
  });
  window.location.reload();
}

async function UpdateEmployeeJob() {
  loading.value = true;

  log(args);
  if (args.branchId && args.branchId.length > 0) {
    args.branchId = getBranchByName(args.branchId)._id;
  }
  if (args.departmentId && args.departmentId.length > 0) {
    args.departmentId = getDepartmentByName(args.departmentId)._id;
  }

  try {
    let res = await mutate({
      endpoint: "UpdateEmployeeJob",
      data: {
        updateEmployeeJobId: employee.value._id,
        job: args,
      },
      service: "EMP",
    });
    console.log(res);
    if (res.success) {
      loading.value = false;
      store.$patch({
        pageIndex: 3,
      });
      // toast.success("Employee's job details updated successfully");
      updateSuccess.value = true;
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      loading.value = false;
    }
  } catch (e) {
    loading.value = false;
    console.log(e);
  }
}
</script>

<style></style>
