<template>
  <div class="p-6 md:p-8 flex flex-col gap-6">
    <div class="w-full flex items-start gap-4">
      <div class="flex flex-col">
        <span class="text-xs text-dark-500">Title</span>
        <span class="text-2xl text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.title)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs text-dark-500">First Name</span>
        <span class="text-2xl text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.firstName)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-xs text-dark-500">Last Name</span>
        <span class="text-2xl text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.lastName)
        }}</span>
      </div>
    </div>

    <!-- ////Avatar//// -->
    <div
      :class="employee.profile.pfp ? 'border-2 border-secondary' : ''"
      class="h-64 w-60 rounded-lg overflow-hidden"
    >
      <img
        v-if="employee.profile.pfp"
        class="w-full h-full object-cover"
        :src="employee.profile.pfp"
        alt=""
      />
      <div
        v-else
        class="bg-secondary text-white text-7xl font-bold flex items-center justify-center w-full h-full"
      >
        {{ getAvatar() }}
      </div>
    </div>

    <!-- ///// Profile Information ///// -->
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Personal Email</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(
            employee && employee.profile && employee.profile.personalEmail
          )
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Work Email</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.email)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Gender</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.gender)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Home Address</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.address)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Phone Number(s)</span>
        <span class="text-base text-dark-800 font-medium"
          >{{ display(employee && employee.profile && employee.profile.phone) }}
          <span
            v-for="(otherPhone, i) in employee &&
            employee.profile &&
            employee.otherPhoneNumbers"
            :key="i"
          >
            ,{{ otherPhone }}</span
          >
        </span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Date of Birth</span>
        <span class="text-base text-dark-800 font-medium">{{
          formatDateString(
            display(employee && employee.profile && employee.profile.dob)
          )
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">State of Origin</span>
        <span class="text-base text-dark-800 font-medium">{{
          display(employee && employee.profile && employee.profile.state)
        }}</span>
      </div>
      <div class="flex flex-col">
        <span class="text-sm text-dark-500">Religion</span>
        <span class="text-base text-dark-800 font-medium capitalize">{{
          display(employee && employee.profile && employee.profile.religion)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatDateString } = helperFunctions;

const store = useDataStore();
const route = useRoute();

const fromArchived = computed(() => store.getViewFromArchived);
console.log("From Archived", fromArchived);

const employee = computed(() =>
  fromArchived.value
    ? store.getArchivedEmployeeById(route.params.id)
    : store.getEmployeeById(route.params.id)
);
console.log("Employee", employee.value);
const getAvatar = () => {
  let name = "";

  employee
    ? (name = `${
        employee.value &&
        employee.value.profile &&
        employee.value.profile.firstName &&
        employee.value.profile.firstName.charAt(0)
      }${
        employee.value &&
        employee.value.profile &&
        employee.value.profile.lastName &&
        employee.value.profile.lastName.charAt(0)
      }`)
    : null;

  return name;
};

const display = (arg) => {
  return arg ? arg : "N/A";
};
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}
</script>

<style></style>
