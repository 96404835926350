<template>
  <div class="md:full w-full py-10 mx-auto text-left">
    <div class="mb-6">
      <h2
        class="px-3 md:px-12 lg:px-8 xl:px-10 text-left text-headerText font-medium text-lg md:text-2xl"
      >
        Edit Health Information
      </h2>
      <hr class="my-4 border-0.5 border-dividerColor text-dividerColor" />
    </div>
    <form
      @submit.prevent="updateHealth"
      autocomplete="on"
      class="flex flex-col px-3 md:px-12 lg:px-8 xl:px-10"
    >
      <label class="text-dark-600 text-sm font-medium">Blood Group</label>
      <easiSelectInput2
        required
        class="mt-1 mb-2"
        @update="args.bloodGroup = $event"
        :value="args.bloodGroup"
        :options="[
          {
            label: 'A',
            value: 'A',
          },
          {
            label: 'B',
            value: 'B',
          },
          {
            label: 'AB',
            value: 'AB',
          },
          {
            label: 'O',
            value: 'O',
          },
        ]"
      />

      <label class="text-dark-600 text-sm font-medium">Genotype</label>
      <easiSelectInput2
        required
        class="mt-1 mb-0"
        @update="args.genotype = $event"
        :value="args.genotype"
        :options="[
          {
            label: 'AA',
            value: 'AA',
          },
          {
            label: 'AS',
            value: 'AS',
          },
          {
            label: 'SS',
            value: 'SS',
          },
          {
            label: 'AC',
            value: 'AC',
          },
          {
            label: 'SC',
            value: 'SC',
          },
          {
            label: 'CC',
            value: 'CC',
          },
        ]"
      />

      <!-- <label for="disabilities" class="text-dark-600 text-sm font-medium"
        >Disabilities</label
      > -->
      <easiTextInput
        placeholder="Disabilities"
        type="text"
        name="disabilities"
        class="mt-1 mb-2"
        v-model="args.disabilities"
      ></easiTextInput>

      <!-- <label for="healthHistory" class="mt-4 text-dark-600 text-sm font-medium"
        >Health history</label
      > -->

      <easiTextArea
        class="focus:border-primary focus:ring-primary border-dark-200 p-16 bg-white w-full text-info-700 font-medium text-sm leading-2 focus:outline-none border py-3 px-4 block appearance-none rounded-2xl mt-1 mb-6"
        name="healthHistory"
        id="healthHistory"
        placeholder="Health history"
        cols="30"
        rows="5"
        v-model="args.healthHistory"
      ></easiTextArea>

      <div
        class="w-full my-3 flex flex-col md:flex-row justify-center justify-items-center place-items-center gap-5 items-center"
      >
        <span
          @click="reloadPage"
          class="md:w-40 w-full cursor-pointer text-center bg-white rounded-full text-dark-600 border border-primary text-primary font-bold px-3 text-sm py-3"
          >Cancel</span
        >
        <easiButton
          :loading="loading"
          class="focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white w-80 md:w-40"
        >
          Update
        </easiButton>
      </div>
    </form>
    <easiSuccess v-if="updateSuccess" @close="updateSuccess = false">
      <template v-slot:message>
        <span>Employee's health info updated successfully</span>
      </template>
    </easiSuccess>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, watch, computed, onMounted } from "vue";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";

const store = useDataStore();
const route = useRoute();
const toast = useToast();

const { mutate } = store;
const updateSuccess = ref(false);

const employee = computed(() => store.getEmployeeById(route.params.id));
// onMounted(() => {

// });

let args = reactive({
  bloodGroup: "",
  genotype: "",
  disabilities: "",
  healthHistory: "",
});
const loading = ref(false);
if (employee.value.health) {
  args = employee.value.health;
  delete args.__typename;
  console.log(args);
}
function reloadPage() {
  store.$patch({
    pageIndex: 2,
  });
  window.location.reload();
}
async function updateHealth() {
  loading.value = true;
  try {
    let res = await mutate({
      endpoint: "UpdateHealth",
      data: {
        employeeId: employee.value._id,
        input: args,
      },
      service: "EMP",
    });
    console.log(res);
    if (res.bloodGroup.length) {
      loading.value = false;
      store.$patch({
        pageIndex: 2,
      });
      // toast.success("Employee's health details updated successfully");
      updateSuccess.value = true;
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  } catch (e) {
    console.log(e);
  }
}
</script>

<style></style>
